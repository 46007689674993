import React from "react";

const IconBackground = ({ className }) => {
  return (
    <svg className={className} width="176" height="152" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_iii)">
        <path
          d="M114.357 0H61.463a32 32 0 00-27.77 16.101L8.977 59.271a32 32 0 00-.186 31.468L33.76 135.57A32.001 32.001 0 0061.716 152h52.387a32 32 0 0027.921-16.367l25.101-44.83a32 32 0 00-.187-31.595l-24.847-43.17A32.001 32.001 0 00114.357 0z"
          fill="#F2F0F4"
        />
      </g>
      <g filter="url(#filter1_di)">
        <path
          d="M101.672 37H74.235a16 16 0 00-13.897 8.072L47.46 67.642a16 16 0 00-.092 15.693l13.003 23.429A16 16 0 0074.362 115h27.183c5.8 0 11.146-3.139 13.973-8.204l13.072-23.43a16.001 16.001 0 00-.093-15.755L115.551 45.04A16 16 0 00101.672 37z"
          fill="#161614"
        />
      </g>
      <defs>
        <filter
          id="filter0_iii"
          x="3.749"
          y="-1"
          width="168.455"
          height="154"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="-1" dy="-1" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix values="0 0 0 0 0.841806 0 0 0 0 0.812778 0 0 0 0 0.870833 0 0 0 1 0" />
          <feBlend in2="shape" result="effect1_innerShadow" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix values="0 0 0 0 0.985469 0 0 0 0 0.979271 0 0 0 0 0.991667 0 0 0 1 0" />
          <feBlend in2="effect1_innerShadow" result="effect2_innerShadow" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix values="0 0 0 0 0.882353 0 0 0 0 0.862745 0 0 0 0 0.905882 0 0 0 0.25 0" />
          <feBlend in2="effect2_innerShadow" result="effect3_innerShadow" />
        </filter>
        <filter
          id="filter1_di"
          x="42.359"
          y="34"
          width="93.258"
          height="86"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.48 0" />
          <feBlend in2="shape" result="effect2_innerShadow" />
        </filter>
      </defs>
    </svg>
  );
};

export { IconBackground };
