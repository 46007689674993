import cn from "classnames";
import React from "react";
import { Image } from "../Image";
import "./styles.css";

export const LinePartners = ({ partners, className }) => {
  return (
    <div className={cn(className, "[ LinePartners ]")}>
      <div className="LinePartners__wrapper">
        <div className="LinePartners_line1">
          {partners
            .concat(partners)
            .concat(partners)
            .concat(partners)
            .map((partner, index) => (
              <div key={index} className="LinePartners_item">
                <Image className="LinePartners_itemImg" image={partner} alt="" />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
