import cn from "classnames";
import { Link } from "gatsby";
import React from "react";
import { paragraph } from "../../utils/paragraph";
import { Image } from "../Image";
import "./styles.css";

const ProductCard = ({ title, text, image, alt, description, trademark, className, href }) => {
  return (
    <Link to={href} className={cn(className, "ProductCard", { ProductCard_single: trademark })}>
      <div className="ProductCard__img">
        <Image image={image} alt={alt} />
      </div>
      <div className="ProductCard__content">
        <h3 className="[ ProductCard__title ] [ Font_smallHeader Color_black ]">{title}</h3>
        <p
          className="[ ProductCard__text ] [ Font_regularText Color_darkGrey ]"
          dangerouslySetInnerHTML={{ __html: paragraph(text) }}
        />
        {description && <p className="[ ProductCard__description ] [ Color_lightGrey ]">{description}</p>}
      </div>
    </Link>
  );
};

export { ProductCard };
