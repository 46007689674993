import { graphql, Link } from "gatsby";
import React from "react";
import { Button } from "../Button";
import { Image } from "../Image";
import { LinePartners } from "../LinePartners/LinePartners";
import { LogoHoney } from "./icons";
import "./styles.css";

const HomeHero = ({ background, alt, subtitle, title, contactUsButtonLabel, productsButtonLabel, partners }) => {
  return (
    <div className="HomeHero">
      <div className="HomeHero__backgroundContainer">
        <Image className="HomeHero__backgroundImage" image={background} alt={alt} />
      </div>

      <div className="[ HomeHero__content ] [ Content ]">
        <div className="HomeHero__info">
          <div className="[ Font_smallHeaderCaps Color_darkGrey sm:Font_mediumTextCaps ]">{subtitle}</div>

          <h1 className="[ HomeHero__title ]  [ Font_bigHeaderSerif  Color_black ] ">{title}</h1>

          <div className="HomeHero__buttonGroup">
            <Link to="/#contact-us">
              <Button className="HomeHero__button" black icon>
                {contactUsButtonLabel}
              </Button>
            </Link>

            {/* <Button className="HomeHero__button" outlined_black icon>
              {productsButtonLabel}
            </Button> */}
          </div>
        </div>

        <LogoHoney className="HomeHero__logoHoney" />
      </div>

      <LinePartners className="HomeHero__partners" partners={partners} />
    </div>
  );
};

HomeHero.getLivePreviewData = ({ data, getAsset }) => ({
  hero: {
    ...data.hero,
    background: getAsset(data.hero.background).url,
  },
});

const homeHeroQuery = graphql`
  fragment HomeHeroFragment on MarkdownRemarkFrontmatter {
    hero {
      background {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      alt
      subtitle
      title
      contactUsButtonLabel
      productsButtonLabel
    }
  }
`;

export { HomeHero, homeHeroQuery };
