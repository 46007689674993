import { graphql } from "gatsby";
import React from "react";
import { ProductCard } from "../ProductCard";
import "./styles.css";

const HomeCatalog = ({ products, trademark }) => {
  products = [
    { ...products[0], href: "/professional-syrups" },
    { ...products[1], href: "/classic-syrups" },
    { ...products[2], href: "/honey" },
    { ...products[3], href: "/packaged-honey" },
  ];
  return (
    <section className="HomeCatalog">
      <div className="Content">
        <div className="HomeCatalog__wrapper">
          {products.map(product => (
            <ProductCard key={product.title} {...product} className="HomeCatalog__productCard" />
          ))}
          <ProductCard href="/own-brand" trademark {...trademark} className="HomeCatalog__productCard" />
        </div>
      </div>
    </section>
  );
};

HomeCatalog.getLivePreviewData = ({ data, getAsset }) => ({
  homeCatalog: {
    ...data.homeCatalog,
    products: data.homeCatalog.products.map(product => ({
      ...product,
      image: getAsset(product.image).url,
    })),
    trademark: {
      ...data.homeCatalog.trademark,
      image: getAsset(data.homeCatalog.trademark.image).url,
    },
  },
});

const homeCatalogQuery = graphql`
  fragment HomeCatalogFragment on MarkdownRemarkFrontmatter {
    homeCatalog {
      products {
        title
        text
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
      }

      trademark {
        title
        text
        description
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
      }
    }
  }
`;

export { HomeCatalog, homeCatalogQuery };
