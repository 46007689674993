import cn from "classnames";
import { Link } from "gatsby";
import React from "react";
import { Button } from "../../Button";
import { IconBackground } from "./IconBackground";
import "./styles.css";

const HomeSolutionsSolution = ({ className, title, text, buttonLabel, buttonLink, icon, reverse }) => {
  return (
    <div className={cn(className, "HomeSolutionsSolution", { HomeSolutionsSolution_reverse: reverse })}>
      <div className="HomeSolutionsSolution__container">
        <div className="HomeSolutionsSolution__icon">
          <IconBackground className="HomeSolutionsSolution__backgroundIcon" />
          <img className="HomeSolutionsSolution__iconIcon" src={icon.publicURL} alt="" />
        </div>
        <div className="HomeSolutionsSolution__content">
          <h3 className="[ HomeSolutionsSolution__title ] [ Font_smallHeaderSerif ]">{title}</h3>
          {text && <p className="[ HomeSolutionsSolution__text ] [ Font_regularText Color_darkGrey ]">{text}</p>}
          {buttonLabel && (
            <Link to={buttonLink}>
              <Button className="HomeSolutionsSolution__button" outlined_yellow>
                {buttonLabel}
              </Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export { HomeSolutionsSolution };
