import { graphql } from "gatsby";
import React from "react";
import { ContactUs } from "../ContactUs";
import "./styles.css";

const HomeContactUs = ({ title, advantages, formHeader, formBody }) => {
  return (
    <ContactUs
      className="HomeContactUs"
      title={title}
      advantages={advantages}
      formHeader={formHeader}
      formBody={formBody}
    />
  );
};

HomeContactUs.getLivePreviewData = ({ data, getAsset }) => ({
  homeContactUs: {
    ...data.homeContactUs,
  },
});

const homeContactUsQuery = graphql`
  fragment HomeContactUsFragment on MarkdownRemarkFrontmatter {
    homeContactUs {
      title
      advantages {
        title
        text
      }

      formHeader {
        title
        text
      }
    }
  }
`;

export { HomeContactUs, homeContactUsQuery };
