import { graphql } from "gatsby";
import React from "react";
import "./styles.css";

const HomeAdvantages = ({ title, advantages }) => {
  return (
    <section className="HomeAdvantages">
      <div className="Content HomeAdvantages__Content">
        <h2 className="[ HomeAdvantages__title ] [ Font_mediumHeaderSerif Color_black ]">{title}</h2>
        <div className="HomeAdvantages__advantagesContainer">
          <div className="HomeAdvantages__advantages">
            {advantages.map(({ title, text, icon }) => (
              <div key={title} className="HomeAdvantages__advantage">
                <div className="HomeAdvantages__advantageIcon">
                  <img src={icon.publicURL} alt="" />
                </div>
                <div className="HomeAdvantages__advantageContent">
                  <h3 className="[ HomeAdvantages__advantageTitle ] [ Font_smallHeaderCaps ]">{title}</h3>
                  <p className="Color_darkGrey Font_regularText">{text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

HomeAdvantages.getLivePreviewData = ({ data, getAsset }) => ({
  homeAdvantages: {
    ...data.homeAdvantages,
    advantages: data.homeAdvantages.advantages.map(advantage => ({
      ...advantage,
      icon: {
        publicURL: getAsset(advantage.icon).url,
      },
    })),
  },
});

const homeAdvantagesQuery = graphql`
  fragment HomeAdvantagesFragment on MarkdownRemarkFrontmatter {
    homeAdvantages {
      title
      advantages {
        title
        text
        icon {
          publicURL
        }
      }
    }
  }
`;

export { HomeAdvantages, homeAdvantagesQuery };
