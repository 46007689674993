import { graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import { HomeAdvantages } from "../components/HomeAdvantages/HomeAdvantages";
import { HomeCatalog } from "../components/HomeCatalog/HomeCatalog";
import { HomeContactUs } from "../components/HomeContactUs/HomeContactUs";
import { HomeHero } from "../components/HomeHero";
import { HomeSolutions } from "../components/HomeSolutions/HomeSolutions";
import { Layout } from "../components/Layout";

const IndexPageTemplate = ({ layout, ...props }) => {
  return (
    <>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>

      <HomeHero partners={layout.partners} {...props.hero} />
      <HomeSolutions {...props.homeSolutions} />
      <HomeCatalog {...props.homeCatalog} />
      <HomeContactUs formBody={layout.formBody} {...props.homeContactUs} />
      <HomeAdvantages {...props.homeAdvantages} />
    </>
  );
};

IndexPageTemplate.getLivePreviewData = props => ({
  ...Layout.getLivePreviewData(props),
  ...HomeHero.getLivePreviewData(props),
  ...HomeSolutions.getLivePreviewData(props),
  ...HomeCatalog.getLivePreviewData(props),
  ...HomeContactUs.getLivePreviewData(props),
  ...HomeAdvantages.getLivePreviewData(props),
});

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout {...data.layout.frontmatter}>
      <IndexPageTemplate layout={data.layout.frontmatter} {...frontmatter} />
    </Layout>
  );
};

const pageQuery = graphql`
  query IndexPageTemplate {
    layout: markdownRemark(frontmatter: { meta: { eq: "layout" } }) {
      frontmatter {
        ...LayoutFragment
        formBody {
          policy
          buttonLabel
          successMessage
          successButtonLabel
          errorMessage
          errorButtonLabel
        }
        partners {
          childImageSharp {
            fluid(maxWidth: 192) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }

    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        seo {
          title
          description
          keywords
        }
        ...HomeHeroFragment
        ...HomeSolutionsFragment
        ...HomeCatalogFragment
        ...HomeContactUsFragment
        ...HomeAdvantagesFragment
      }
    }
  }
`;

export default IndexPage;

export { pageQuery, IndexPageTemplate };
