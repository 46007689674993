import { graphql } from "gatsby";
import React from "react";
import { HomeSolutionsSolution } from "./HomeSolutionsSolution";
import "./styles.css";

const HomeSolutions = ({ subtitle, title, solutions }) => {
  return (
    <div className="HomeSolutions">
      <div className="Content">
        <div className="HomeSolutions__info">
          <div className="[ HomeSolutions__subtitle ] [ Font_smallHeaderCaps sm:Font_mediumTextCaps Color_lightGrey ]">
            {subtitle}
          </div>
          <div className="[ HomeSolutions__title ] [ Font_mediumHeaderSerif Color_black ]">{title}</div>
        </div>
        <div className="HomeSolutions__solutions">
          {solutions.map((solution, index) => (
            <HomeSolutionsSolution
              key={solution.title}
              className="HomeSolutions__solution"
              {...solution}
              reverse={index % 2 === 1}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

HomeSolutions.getLivePreviewData = ({ data, getAsset }) => ({
  homeSolutions: {
    ...data.homeSolutions,
    solutions: data.homeSolutions.solutions.map(solution => ({
      ...solution,
      icon: { publicURL: solution.icon && getAsset(solution.icon).url },
    })),
  },
});

const homeSolutionsQuery = graphql`
  fragment HomeSolutionsFragment on MarkdownRemarkFrontmatter {
    homeSolutions {
      subtitle
      title
      solutions {
        type
        title
        text
        buttonLabel
        buttonLink
        icon {
          publicURL
        }
      }
    }
  }
`;

export { HomeSolutions, homeSolutionsQuery };
